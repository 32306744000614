import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Elements } from 'react-stripe-elements';
import FormSelect from 'components/common/FormSelect';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import { UPDATE_SAVED_CARD_IN_CATEGORING_ORDER } from 'redux/catering/actionTypes';
import InjectedCheckoutForm from './InjectedCheckoutForm';

const StripeFormModal = ({
  show,
  onClose,
  orderHandler,
  loadingHandler,
  totalPrice,
  cateringOrder,
  clearCateringOrder,
  setSaveCard,
  isEft,
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    const channel = new BroadcastChannel('card_channel');
    channel.onmessage = event => {
      const eventCard = event.data;
      dispatch({
        type: UPDATE_SAVED_CARD_IN_CATEGORING_ORDER,
        data: eventCard,
      });
    };
    return () => {
      channel.close();
    };
  }, []);
  const defaultSelectedCard = useMemo(
    () =>
      cateringOrder?.savedCards?.find(
        item =>
          item?.isDefault &&
          (item.paymentType === 'card' || item.paymentType == null),
      ),
    [cateringOrder?.savedCards],
  );
  const options = useMemo(
    () =>
      cateringOrder?.savedCards
        ?.filter(
          item =>
            item.paymentType === 'card' || item.paymentType !== 'acss_debit',
        )
        ?.map(item => ({
          label: item.name,
          value: item.paymentMethodId,
        })),
    [cateringOrder?.savedCards],
  );

  useEffect(() => {
    setSelectedCard(
      defaultSelectedCard
        ? {
            label: defaultSelectedCard?.name,
            value: defaultSelectedCard?.paymentMethodId,
          }
        : null,
    );
    if (defaultSelectedCard) {
      setActive(defaultSelectedCard?.paymentMethodId);
    } else if (cateringOrder && !cateringOrder?.savedCards?.length) {
      setActive('add');
    } else {
      setActive(null);
      setSelectedCard(null);
    }
  }, [cateringOrder, defaultSelectedCard, cateringOrder?.savedCards]);

  const handleSubmit = () => {
    if (active && cateringOrder.purchaseId !== 'add') {
      orderHandler(active);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      />
      <Modal.Header>
        <h3>Complete Order</h3>
      </Modal.Header>
      <Modal.Body className="pb-5">
        <>
          {cateringOrder?.savedCards?.length ? (
            <FormSelect
              name="numPeopleRange"
              placeholder="Pay with"
              className="mb-5"
              value={selectedCard}
              options={[...options, { label: 'Add new card', value: 'add' }]}
              onChange={(_, value, card) => {
                setActive(value);
                setSelectedCard(card);
              }}
            />
          ) : null}
          {!cateringOrder ? <LoadingAnimation /> : null}
          {active === 'add' && cateringOrder ? (
            <>
              <Elements>
                <InjectedCheckoutForm
                  {...{
                    orderHandler,
                    loadingHandler,
                    totalPrice,
                    setSaveCard,
                  }}
                />
              </Elements>
            </>
          ) : (
            <>
              <button
                type="button"
                disabled={!active}
                onClick={handleSubmit}
                className="btn btn-block btn-primary"
              >
                Pay ${priceDecimalFormatter(totalPrice)}
              </button>
              <>
                {defaultSelectedCard &&
                defaultSelectedCard?.brand &&
                defaultSelectedCard?.last4 ? (
                  <div className="form-footer mt-4 text-center">
                    <span className="mr-2">
                      Paying with {defaultSelectedCard?.brand} ending with{' '}
                      {defaultSelectedCard?.last4}
                    </span>
                    <Link
                      target="_blank"
                      className="border-bottom"
                      to="/payments"
                    >
                      Change
                    </Link>
                  </div>
                ) : null}
              </>
            </>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

StripeFormModal.propTypes = {
  show: PropTypes.bool,
  isEft: PropTypes.bool,
  onClose: PropTypes.func,
  orderHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  setSaveCard: PropTypes.func,
  totalPrice: PropTypes.number.isRequired,
  clearCateringOrder: PropTypes.func.isRequired,
  cateringOrder: PropTypes.shape({
    savedCards: PropTypes.arrayOf(
      PropTypes.objectOf({
        name: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    ),
    clientSecret: PropTypes.string,
    purchaseId: PropTypes.number.isRequired,
  }).isRequired,
};

StripeFormModal.defaultProps = {
  show: true,
  isEft: false,
  onClose: () => {},
  orderHandler: () => {},
  loadingHandler: () => {},
  setSaveCard: () => {},
};

export default StripeFormModal;
