import { toCamelKeys, toSnakeKeys } from 'utils/convert.util';
import { store } from 'index';
import { signoutRequest } from 'redux/actions/authActions';

require('dotenv').config();

export async function handleResponse(response) {
  const jsonData = response.json();
  if (response.ok) return jsonData;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error('Network response was not ok.');
}

export async function handleTokenResponse(response) {
  if (response.ok) return response.text();
  throw new Error('Token response was not ok.');
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error(`API call failed. ${error}`);
  throw error;
}

export function getHeaderInfo() {
  const userInfo = store.getState().user;
  const token = userInfo.jwt;
  const headerInfo = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
    // 'ngrok-skip-browser-warning': 'true', // Add this header
    Authorization: `Bearer ${token}`,
  };

  return headerInfo;
}

export function setHeaderInfo(newToken) {
  localStorage.setItem('jwt', newToken);
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.text();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    store.dispatch(signoutRequest());
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function getGroupOrderURL(groupOrderUuid) {
  console.log('debug.................');
  console.log(
    `REACT_APP_GROUP_ORDER_APP_URL: ${process.env.REACT_APP_GROUP_ORDER_APP_URL}`,
  );
  return `${process.env.REACT_APP_GROUP_ORDER_APP_URL}${groupOrderUuid}`;
}

export function fetchAPI(url, method = 'GET', payload = null) {
  const requestUrl = `${process.env.REACT_APP_API_URL}/${url}`;
  const options = { method };
  const headers = getHeaderInfo();

  // maybe we need to set global API loading status const store = getStore();
  // store.dispatch(setAPILoading(true));

  if (payload) {
    // convert into snake case first
    options.body = JSON.stringify(toSnakeKeys(payload));
    // console.log(options.body);
  }
  return fetch(requestUrl, {
    ...options,
    headers,
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(resp => {
      // store.dispatch(setAPILoading(false));
      // maybe we need to set global app loading status
      return toCamelKeys(resp ? JSON.parse(resp) : {});
    })
    .catch(err => {
      if (err.response) {
        // err.response.json().then(json => {
        //   console.log('---> error message', json);
        //   // same stuff
        //   return json;
        // });
      } else {
        // show alert
      }
      // store.dispatch(setAPILoading(false));
      throw err;
    });
}

export function fetchFile(url, name, method = 'GET') {
  const requestUrl = `${process.env.REACT_APP_API_URL}/${url}`;
  const options = { method };
  const headers = getHeaderInfo();

  return fetch(requestUrl, {
    ...options,
    headers,
  })
    .then(res => {
      return res.blob();
    })
    .then(data => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = name;
      a.click();
    })
    .catch(err => {
      throw err;
    });
}
