import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './index.css';

const FormSelect = ({
  name,
  placeholder,
  options,
  onChange,
  onBlur,
  onFocus,
  className,
  value,
}) => (
  <Select
    {...{ name, placeholder, onBlur, onFocus }}
    options={
      typeof options[0] === 'string'
        ? options.map(option => ({ label: option, value: option }))
        : options
    }
    className={`react-select-container ${className}`}
    classNamePrefix="react-select"
    components={{ IndicatorSeparator: null }}
    theme={theme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#d6ae3a',
        primary50: '#d6ae3a',
        primary: '#d6ae3a',
      },
    })}
    onChange={option => {
      onChange(name, option.value, option);
    }}
    value={value}
  />
);

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.string ||
    PropTypes.objectOf({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

FormSelect.defaultProps = {
  placeholder: '',
  className: '',
  options: [],
  onChange: () => { },
  onBlur: () => { },
  onFocus: () => { },
};

export default FormSelect;
