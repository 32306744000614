import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import '../style.scss';
import { loadPaymentsMethodList } from 'redux/actions/paymentActions';
import EFTCard from './eftCard';
import StripeCard from './stripeCard';

const AddNewPaymentMethod = ({ activeTab, setActiveTab }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPaymentsMethodList());
  }, [dispatch]);

  const handleTabSwitch = tab => {
    setActiveTab(tab);
  };

  return (
    <div className="container">
      <div className="modal fade" id="paymentModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <button
              id="modalCloseButton"
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setActiveTab('')}
            />
            <div className="modal-header justify-content-center">
              <h3 className="m-0">Please select your payment method</h3>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  {/* eslint-disable-next-line react/button-has-type */}
                  <button
                    className={`nav-link ${activeTab === 'card' ? 'active' : ''
                      }`} // Fixed string interpolation
                    onClick={() => handleTabSwitch('card')}
                  >
                    Credit Card
                  </button>
                </li>
                <li className="nav-item">
                  {/* eslint-disable-next-line react/button-has-type */}
                  <button
                    className={`nav-link ${activeTab === 'eft' ? 'active' : ''
                      }`} // Fixed string interpolation
                    onClick={() => handleTabSwitch('eft')}
                  >
                    Bank Account
                  </button>
                </li>
              </ul>
              <div className="tab-content">
                {activeTab === 'card' && (
                  <div className="tab-pane fade show active">
                    <StripeCard activeTab={activeTab} />
                  </div>
                )}
                {activeTab === 'eft' && (
                  <div className="tab-pane fade show active">
                    <EFTCard />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AddNewPaymentMethod.propTypes = {
  activeTab: PropTypes.string.isRequired, // activeTab is a required string
  setActiveTab: PropTypes.func.isRequired, // setActiveTab is a required function
};
export default AddNewPaymentMethod;
