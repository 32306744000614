/* eslint-disable react/button-has-type */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import { finalizingCateringOrderAction } from 'redux/actions/paymentActions';
import { useHistory } from 'react-router-dom';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { store } from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import FormSelect from 'components/common/FormSelect';
import { UPDATE_SAVED_CARD_IN_CATEGORING_ORDER } from 'redux/catering/actionTypes';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const EftFormModal = ({
  show,
  onClose,
  cateringOrder,
  clearCateringOrder,
  orderHandler,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [saveCard, setSaveCard] = useState(false);

  useEffect(() => {
    const channel = new BroadcastChannel('card_channel');
    channel.onmessage = event => {
      const eventCard = event.data;
      dispatch({
        type: UPDATE_SAVED_CARD_IN_CATEGORING_ORDER,
        data: eventCard,
      });
    };
    return () => {
      channel.close();
    };
  }, []);

  const defaultSelectedCard = useMemo(
    () => cateringOrder?.savedCards?.find(item => item?.isDefault && item.paymentType === 'acss_debit'),
    [cateringOrder?.savedCards],
  );

  const savedCardOptions = useMemo(
    () =>
      cateringOrder?.savedCards
        ?.filter(item => item.paymentType === 'acss_debit')
        ?.map(item => ({
          label: item.name,
          value: item.paymentMethodId,
        })),
    [cateringOrder?.savedCards],
  );

  useEffect(() => {
    setSelectedCard(
      defaultSelectedCard
        ? {
          label: defaultSelectedCard?.name,
          value: defaultSelectedCard?.paymentMethodId,
        }
        : null,
    );
    if (defaultSelectedCard) {
      setActive(defaultSelectedCard?.paymentMethodId);
    } else if (cateringOrder && !cateringOrder?.savedCards?.length) {
      setActive('add');
    } else {
      setActive(null);
      setSelectedCard(null);
    }
  }, [cateringOrder, defaultSelectedCard, cateringOrder?.savedCards]);

  const stripeOptions = {
    clientSecret: cateringOrder?.clientSecret,
    fields: {
      billingDetails: {
        name: 'never',
        email: 'never',
      },
    },
  };

  const userName = cateringOrder?.fullName;
  const userEmail = cateringOrder?.email;

  const handleOnSubmitExistingCard = () => {
    if (active && cateringOrder.purchaseId !== 'add') {
      orderHandler(active);
    }
  };

  const RenderPaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const onSuccess = () => {
      history.push('/checkout-completed');
    };

    const handleSubmit = async () => {
      if (!stripe || !elements) {
        return;
      }
      const clientSecret = cateringOrder?.clientSecret;
      setIsLoading(true);
      const { setupIntent, error } = await stripe.confirmAcssDebitSetup(
        clientSecret,
        {
          payment_method: {
            billing_details: {
              name: userName,
              email: userEmail,
            },
          },
        },
      );

      if (error) {
        onClose();
        store.addNotification({
          message: error.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 8000,
          },
        });
      }
      if (setupIntent.status === 'succeeded') {
        try {
          const paymentMethodId = setupIntent.payment_method;
          const purchaseId = cateringOrder?.purchaseId;
          await finalizingCateringOrderAction(
            paymentMethodId,
            purchaseId,
            saveCard,
            onSuccess,
            setIsLoading,
          );
          await clearCateringOrder();
        } catch (error) {
          onClose();
          store.addNotification({
            message: error,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 8000,
            },
          });
        }
      }
    };

    return (
      <>
        {isLoading ?
          <LoadingAnimation /> :
          null
        }
        <div className="row eft-modal">
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-nameInput"
                className="form-control"
                type="text"
                name="full_name"
                required="required"
                value={userName}
                disabled
              />
              <label>Full Name</label>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="labeled-group floating">
              <input
                id="Field-emailInput"
                className="form-control"
                type="email"
                name="email"
                value={userEmail}
                required="required"
                disabled
              />
              <label>Email</label>
            </div>
          </div>

          <div className="custom-control custom-checkbox custom-checkbox-100">
            <input
              className="custom-control-input"
              type="checkbox"
              id="saveCard"
              checked={saveCard}
              onChange={() => setSaveCard(!saveCard)}
            />
            <label
              className="custom-control-label"
              htmlFor="saveCard"
              style={{ marginBottom: '20px' }}
            >
              <span>
                By checking this box, you agree to save your bank account for
                later. This will speed up your checkout process.
              </span>
            </label>
          </div>
        </div>

        <PaymentElement options={stripeOptions} />
        <button
          onClick={handleSubmit}
          disabled={isLoading || !stripe}
          className="btn btn-block btn-primary"
        >
          Confirm and Proceed to Payment
        </button>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onClose}>
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      />
      <Modal.Header>
        <h3>Verify Your Details</h3>
      </Modal.Header>
      <Modal.Body className="pb-5">
        {cateringOrder?.savedCards?.length ? (
          <FormSelect
            name="numPeopleRange"
            placeholder="Pay with"
            className="mb-5"
            value={selectedCard}
            options={[
              ...savedCardOptions,
              { label: 'Add new bank account', value: 'add' },
            ]}
            onChange={(_, value, card) => {
              setActive(value);
              setSelectedCard(card);
            }}
          />
        ) : null}
        {cateringOrder && cateringOrder.clientSecret ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            {active === 'add' && <RenderPaymentForm />}
          </Elements>
        ) : (
          <LoadingAnimation />
        )}
        {active !== 'add' && (
          <button
            onClick={handleOnSubmitExistingCard}
            disabled={!active}
            className="btn btn-block btn-primary"
          >
            Confirm and Proceed to Payment
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};

EftFormModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  orderHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  setSaveCard: PropTypes.func,
  totalPrice: PropTypes.number.isRequired,
  clearCateringOrder: PropTypes.func.isRequired,
  cateringOrder: PropTypes.shape({
    savedCards: PropTypes.arrayOf(
      PropTypes.objectOf({
        name: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    ),
    clientSecret: PropTypes.string,
    purchaseId: PropTypes.number.isRequired,
  }).isRequired,
};

EftFormModal.defaultProps = {
  show: true,
  onClose: () => { },
  orderHandler: () => { },
  loadingHandler: () => { },
  setSaveCard: () => { },
};

export default EftFormModal;
