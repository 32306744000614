import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PaymentIcon from 'react-payment-icons-inline';
import '../../style.scss';
import { loadPaymentsMethodList } from 'redux/actions/paymentActions';
import { makeDefaultPaymentMethod, deletePaymentMethod } from 'api/paymentApi';
import LoadingAnimation from 'components/common/LoadingAnimation';
import ConfirmModal from 'components/common/ConfirmModal';
const CheckedIcon = 'assets/img/payment/checked.svg';
const InactiveIcon = 'assets/img/payment/inactive.svg';
const MapBg = 'assets/img/payment/map.png';
const GlobeBg = 'assets/img/payment/globe.png';
const Chip = 'assets/img/payment/chip.png';
const Rfid = 'assets/img/payment/signal-stream.png';
const Close = 'assets/img/payment/delete.png';

const DebitPayment = ({ card }) => {
  const { brand, id, isDefault, expiry, last4, payment_type } = card || {};
  const [showRemoveCard, setshowRemoveCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSetDefault = async id => {
    try {
      setIsLoading(true);
      await makeDefaultPaymentMethod(id);
      await dispatch(loadPaymentsMethodList());
      const channel = new BroadcastChannel('card_channel');
      channel.postMessage({
        ...card,
        isDefault: !isDefault,
        name: card?.description,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteCard = async () => {
    setshowRemoveCard(false);
    try {
      setIsLoading(true);
      await deletePaymentMethod(id);
      await dispatch(loadPaymentsMethodList());
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const Loader = useMemo(() => {
    if (isLoading) {
      return <LoadingAnimation />;
    }
    return null;
  }, [isLoading]);

  const isExpired = useMemo(() => {
    if (payment_type && payment_type !== 'acss_debit') {
      const [month, year] = expiry.split('/').map(Number);
      const today = new Date();
      const currentMonth = today.getMonth() + 1; // getMonth() returns 0-based month
      const currentYear = Number(
        new Intl.DateTimeFormat('en', { year: '2-digit' }).format(today),
      );
      if (year < currentYear) {
        return true; // Card is expired
      }
      if (year === currentYear && month < currentMonth) {
        return true; // Card is expired
      }
      return false; // Card is not expired
    }
    return false;
  }, [expiry]);

  const ConfirmModalMemorized = useMemo(() => {
    if (showRemoveCard) {
      return (
        <ConfirmModal
          show
          description="Are you sure you want to remove this payment method?"
          confirmText="Remove"
          onConfirm={handleDeleteCard}
          onClose={() => {
            setshowRemoveCard(false);
          }}
        />
      );
    }
    return null;
  }, [showRemoveCard, handleDeleteCard, setshowRemoveCard]);

  return (
    <>
      {ConfirmModalMemorized}
      {Loader}
      <div
        key={id}
        className={`card-inner justify-content-between ${isExpired ? 'expired' : isDefault ? 'active' : null
          }`}>
        <div onClick={() => setshowRemoveCard(true)} className='delete-card'>
          <img src={Close} alt='close button' />
        </div>
        <img className="map-img" src={MapBg} />
        <img className="chip-img" src={Chip} />

        <div className="cardheader">
          <div className="form-group mb-0 checkbox">
            <div
              className="custom-checkbox"
              onClick={e => handleSetDefault(id)}
            >
              {isDefault ? (
                <span>
                  <img src={CheckedIcon} />
                </span>
              ) : (
                <span>
                  <img src={InactiveIcon} />
                </span>
              )}
            </div>
            <label className="mb-0 text-white">
              {isExpired ? (
                <span className="text-dark">Expired</span>
              ) : isDefault ? (
                <span className="text-white">Default</span>
              ) : (
                <span className="text-dark">Mark as Default</span>
              )}
            </label>
          </div>
          <PaymentIcon className="card-icon" icon={brand.toLowerCase()} />
        </div>

        <div className="form-group my-2 exp-date">
          <label htmlFor={`expiryDate-${id}`}>Expiry Date:</label>
          <span className="date-wrap">{expiry}</span>
        </div>
        <div className="form-group mb-0 card-number">
          <label htmlFor={`cardNumber-${id}`}>Card Number:</label>
          <div className="input-wrap">
            {last4 ? (
              <>
                <span>**** **** **** {last4}</span>
              </>
            ) : (
              <span>0000 0000 0000 0000</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DebitPayment;
