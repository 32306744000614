import { fetchAPI } from './apiUtils';

export const PAYMENT_INTENT_URL = 'api/v2/payment_intent';
export const PAYMENT_METHODS_URL = 'api/v2/user_payment_methods';
export const PAYMENT_DEFAULT_METHOD_URL = 'api/v2/payment_methods/';
export const PAYMENT_DELETE_METHOD_URL = 'api/v2/user_payment_methods/';
export const PAYMENT_CREATE_METHOD_URL = 'api/v2/user_payment_methods/';
export const INTENT_CREATE_METHOD_URL = 'api/v2/payment_methods/create_setup_intent';

export function getPaymentIntent(formData) {
  return fetchAPI(PAYMENT_INTENT_URL, 'POST', formData);
}
export function getPaymentMethods() {
  return fetchAPI(PAYMENT_METHODS_URL, 'GET');
}
export function makeDefaultPaymentMethod(id) {
  return fetchAPI(PAYMENT_DEFAULT_METHOD_URL + id + '/set_as_default', 'POST');
}
export function deletePaymentMethod(id) {
  return fetchAPI(PAYMENT_DELETE_METHOD_URL + id, 'DELETE');
}
export function createPaymentMethod(body) {
  return fetchAPI(PAYMENT_CREATE_METHOD_URL, 'POST', body);
}
export function createSetupIntent(body) {
  return fetchAPI(INTENT_CREATE_METHOD_URL, 'POST', body);
}
export function postPaymentResult(intentID, formData) {
  return fetchAPI(`${PAYMENT_INTENT_URL}/${intentID}`, 'PUT', formData);
}
