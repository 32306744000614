// ./src/pages/Payments/index.jsx
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { loadPaymentsMethodList } from 'redux/actions/paymentActions';
import EftCard from './card/eft';
import DebitPayment from './card/debit';
import AddNewPaymentMethod from './add';
import LoadingAnimation from 'components/common/LoadingAnimation';
const OrderSent = 'assets/img/order-sent.svg';
const PaymentMethods = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('');
  const { paymentsMethods, isLoading } = useSelector(state => state.paymentsMethod);

  useEffect(() => {
    dispatch(loadPaymentsMethodList());
  }, [dispatch]);
  const LoadAnimation = useMemo(() => {
    if (isLoading) {
      return <LoadingAnimation />
    }
    return null;
  }, [isLoading]);

  return (
    <div className="container">
      {LoadAnimation}
      {paymentsMethods.length > 0 ? (
        <>
          <div className="main-header justify-content-end">
            <button
              className="btn btn-outline-primary"
              data-toggle="modal"
              data-target="#paymentModal"
              onClick={() => setActiveTab('card')}
              type='button'
            >
              Add Payment Method
            </button>
          </div>

          <div className="mb-5 pb-5 border-bottom">
            <h2>Credit Cards:</h2>
            {paymentsMethods.some(item => item?.paymentType !== 'acss_debit') ? (
              <div className="card-grid">
                {paymentsMethods
                  .filter(item => item?.paymentType !== 'acss_debit')
                  .map(card => (
                    <DebitPayment key={card.id} card={card} />
                  ))}
              </div>
            ) : (
              <h2 className="text-center bank-no-found border d-flex justify-content-center align-items-center flex-column">
                {/* <img
                  style={{ width: '100px' }}
                  src={OrderSent}
                  alt="No Card Found"
                  className='mb-5'
                /> */}
                <span className='d-inline-block text-muted'>
                  No Credit Card(s) Found. Please add a one by clicking Add New
                  Method.
                </span>
              </h2>
            )}
          </div>

          <div>
            <h2>Bank Accounts:</h2>
            {paymentsMethods.some(item => item?.paymentType === 'acss_debit') ? (
              <div className="card-grid">
                {paymentsMethods
                  .filter(item => item?.paymentType === 'acss_debit')
                  .map(card => (
                    <EftCard key={card.id} card={card} />
                  ))}
              </div>
            ) : (
              <h2 className="text-center  bank-no-found border d-flex justify-content-center align-items-center flex-column">
                {/* <img
                  style={{ width: '100px' }}
                  src={OrderSent}
                  alt="No EFT Method Found"
                  className='mb-5'

                /> */}
                <span className='d-inline-block text-muted'>
                  No Bank Account(s) Found. Please add a one by clicking Add New
                  Method.
                </span>
              </h2>
            )}
          </div>
        </>
      ) : (
        <div className='text-center border no-card-found mt-5'>
          {/* <img src={OrderSent} alt="" /> */}
          <p className='font-weight-light text-muted'>There doesn’t seem to be any saved cards or bank accounts here...</p>
          <p className='font-weight-light text-muted'>Why don't you add one to make checking out faster.</p>
          <button
            onClick={() => setActiveTab('card')}
            data-toggle="modal"
            data-target="#paymentModal"
            className=' d-inline-block addMethoad btn btn-outline-primary' >Add Payment Methoad</button>
          <a href="/" className='text-primary d-block go-back'>Go to Home</a>
        </div>
      )}
      <AddNewPaymentMethod activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
};

export default PaymentMethods;
