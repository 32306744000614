import * as types from './actionTypes';
import * as paymentApi from '../../api/paymentApi';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { finalizeCateringOrder } from 'api/cateringApi';
import { createPaymentMethod } from '../../api/paymentApi';
import { createSetupIntent } from '../../api/paymentApi';

export function loadPaymentsMethodListSuccess(paymentsMethods) {
  return { type: types.FETCH_PAYMENT_METHODS_LIST_SUCCESS, paymentsMethods };
}
export function loadPaymentIntentSuccess(paymentIntent) {
  return { type: types.FETCH_PAYMENT_SECRET_SUCCESS, paymentIntent }
}
export function loadPaymentsMethodList() {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.LOADING_PAYMENT_METHOD, isLoading: true });
    try {
      const paymentsMethods = await paymentApi.getPaymentMethods();
      const action = loadPaymentsMethodListSuccess(paymentsMethods?.sort((a, b) => {
        return (b?.isDefault === true) - (a?.isDefault === true);
      }));
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    } finally {
      dispatch({ type: types.LOADING_PAYMENT_METHOD, isLoading: false });
    }
  };
}

export function onCreatePaymentMethod(props) {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      await createPaymentMethod(props);
      const closeButton = document.getElementById("modalCloseButton");
      if (closeButton) {
        closeButton.click();
      }
      dispatch(loadPaymentsMethodList());
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function onCreateSetupIntent() {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const response = await createSetupIntent();
      dispatch(loadPaymentIntentSuccess(response));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export const finalizingCateringOrderAction = async (
  paymentMethodId,
  purchaseId,
  saveCard,
  onSuccess,
  setIsLoading,
) => {
  try {
    await finalizeCateringOrder(
      paymentMethodId,
      purchaseId,
      saveCard,
    );
    onSuccess();
  } catch (e) {
    const { error } = await e.response.json();
    throw error;
  } finally {
    setIsLoading(false);
  }
};
