/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initialState = {
  loading: false,
  loadingSuggestions: false,
  suggestions: null,
  cancelledOrder: false,
  cateringOrder: null,
  cateringOrderInfo: null,
  finalizeCateringOrder: null,
  groupOrderInfo: null,
  error: null,
};

export default function cateringsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CATERING_ORDER_REQUEST:
    case types.UPDATE_CATERING_ORDER_REQUEST:
    case types.FINALIZE_CATERING_ORDER_REQUEST:
    case types.CANCEL_CATERING_ORDER_REQUEST:
    case types.CANCEL_GROUP_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CATERING_ORDER_REQUEST:
    case types.GET_GROUP_ORDER_REQUEST:
      return {
        ...state,
        cateringOrderInfo: initialState.cateringOrderInfo,
        groupOrderInfo: initialState.groupOrderInfo,
        loading: true,
        cancelledOrder: false,
      };
    case types.UPDATE_CATERING_ORDER_SUCCESS:
    case types.FETCH_CATERING_ORDER_SUCCESS:
      return {
        ...state,
        cateringOrder: action.data,
        loading: false,
      };
    case types.UPDATE_SAVED_CARD_IN_CATEGORING_ORDER:
      return {
        ...state,
        cateringOrder: {
          ...state.cateringOrder,
          savedCards: (() => {
            const cardIndex = state.cateringOrder.savedCards.findIndex(card => card.paymentMethodId === action.data.paymentMethodId);
            if (cardIndex !== -1) {
              // Update the existing card
              const updatedCards = [...state.cateringOrder.savedCards];
              updatedCards[cardIndex] = { ...updatedCards[cardIndex], ...action.data };
              return updatedCards;
            } else {
              // Add new card if no match
              return [...state.cateringOrder.savedCards, action.data];
            }
          })(),
        },    
        loading: false,
      };
    case types.GET_CATERING_ORDER_SUCCESS:
      return {
        ...state,
        cateringOrderInfo: action.data,
        loading: false,
      };
    case types.FINALIZE_CATERING_ORDER_SUCCESS:
      return {
        ...state,
        finalizeCateringOrder: action.data,
        loading: false,
      };
    case types.GET_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        groupOrderInfo: action.data,
        loading: false,
      };
    case types.CLEAR_CATERING_ORDER:
    case types.UPDATE_CATERING_ORDER_FAILED:
    case types.FETCH_CATERING_ORDER_FAILED:
      return {
        ...state,
        cateringOrder: initialState.cateringOrder,
        finalizeCateringOrder: initialState.finalizeCateringOrder,
        cateringOrderInfo: initialState.cateringOrderInfo,
        groupOrderInfo: initialState.groupOrderInfo,
        loading: false,
      };
    case types.CANCEL_CATERING_ORDER_SUCCESS:
    case types.CANCEL_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        cancelledOrder: true,
        loading: false,
      };
    case types.GET_CATERING_ORDER_FAILED:
    case types.GET_GROUP_ORDER_FAILED:
    case types.CANCEL_CATERING_ORDER_FAILED:
    case types.CANCEL_GROUP_ORDER_FAILED:
    case types.CATERING_ORDER_SUGGESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        loadingSuggestions: false,
        error: action.error,
      };
    case types.FINALIZE_CATERING_ORDER_FAILED:
      return {
        ...state,
        finalizeCateringOrder: initialState.cateringOrder,
        loading: false,
      };
    case types.CLEAR_CATERING_ORDER_SUGGESTIONS:
      return {
        ...state,
        suggestions: initialState.suggestions,
        loadingSuggestions: false,
      };
    case types.CATERING_ORDER_SUGGESTIONS_REQUEST:
      return {
        ...state,
        suggestions: initialState.suggestions,
        loadingSuggestions: true,
      };
    case types.CATERING_ORDER_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.data,
        loadingSuggestions: false,
      };

    default:
      return state;
  }
}
