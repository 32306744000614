
import * as types from '../actions/actionTypes';
import initialState from './initialState';
export default function paymentIntentReducer(state = initialState.paymentIntent, action) {
    switch (action.type) {
        case types.FETCH_PAYMENT_SECRET_SUCCESS:
            return action.paymentIntent;
        case types.FETCH_PAYMENT_SECRET_FAILED:
            return {};
        default:
            return state;
    }
}